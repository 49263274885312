import React from 'react'
import { graphql } from 'gatsby'
import NycShowroomTemplate, { NycShowroomProps } from '~/templates/nyc-showroom'

export const query = graphql`
  query {
    pageData: markdownRemark(frontmatter: { templateKey: { eq: "nyc-showroom" } }) {
      frontmatter {
        headerVariant
        header {
          title
          description
          image {
            ...heroImage
          }
          imageMobile {
            ...mobileHeroImage
          }
        }
        showroomTitle
        openHours {
          day
          time
        }
        headerQuote
        showroomLink
        address {
          line1
          line2
        }
        carousel {
          image {
            ...heroImage
          }
          imageMobile {
            ...mobileHeroImage
          }
        }
      }
      html
    }
  }
`

type Props = {
  data: {
    pageData: NycShowroomProps['data']
  }
}

const NycShowroom = ({ data: { pageData } }: Props) => <NycShowroomTemplate data={pageData} />

export default NycShowroom
